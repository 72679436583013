<template>
  <div class="content-wrapper">
    <div class="container-fluid">
      <section class="content">
        <div class="box text-center">
          <div class="box-body py-50">
            <i class="fa fa-frown fs-70 text-danger"></i>
            <div class="content-header">
              <h1 class="fw-300 fs-36" v-html="title"></h1>
            </div>
            <a v-if="hideshow == false" @click="back" href="javascript:void(0)" class="waves-effect waves-light btn btn-info me-1">Back </a>
            <a v-if="hideshow == true" @click="login" href="javascript:void(0)" class="waves-effect waves-light btn btn-info-light">Login</a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
     return {
       button: ''
     }
    },
    computed: {
      title() {
        return this.$route.name === "NotFound"
          ? "The page you have requested wasn't found"
          : "You <b>do not have permission</b> to access this section, please contact your Clinic Manager.";
      },
      hideshow(){
        return localStorage.getItem("authToken") === null
          ? true
          : false
      }
    },
    methods:{
      back(){
        this.$router.go(-1);
      },
      login(){
        this.$router.push({path: '/login'});
      } 
    }
  }
</script>